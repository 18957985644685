import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  // const outerClasses = classNames(
  //   'cta section center-content-mobile reveal-from-bottom',
  //   topOuterDivider && 'has-top-divider',
  //   bottomOuterDivider && 'has-bottom-divider',
  //   hasBgColor && 'has-bg-color',
  //   invertColor && 'invert-color',
  //   className
  // );

  // const innerClasses = classNames(
  //   'cta-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider',
  //   split && 'cta-split'
  // );  

  return (
    <section>
      
    </section>
     
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;